import { FC } from "react";

import { Box, Stack, Text } from "@spwn-portal/ui/chakra";

import { Navigate } from "../features/Navigate";
import { pagesPath } from "../lib/$path";
import { EarthIcon } from "../ui/EarthIcon";
import { LinkButton } from "../ui/LinkButton";

export const NotFound: FC = () => {
  return (
    <Stack direction={"column"} spacing={6} alignItems={"center"}>
      <Box>
        <EarthIcon boxSize={"60px"} />
      </Box>

      <Stack direction={"column"} spacing={2}>
        <Text fontSize={["lg", "2xl"]} fontWeight={"bold"} textAlign={"center"}>
          このページへアクセスできません。
        </Text>

        <Text fontSize={["sm", "md"]} textAlign={"center"}>
          お探しのページは見つかりませんでした。
        </Text>
      </Stack>

      <Box textAlign={"center"}>
        <Navigate href={pagesPath.$url()}>
          <LinkButton variant={"outline"} size={"md"}>
            トップに戻る
          </LinkButton>
        </Navigate>
      </Box>
    </Stack>
  );
};
