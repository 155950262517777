import NextHeadSeo from "next-head-seo";
import { FC } from "react";

import { Container, Flex } from "@spwn-portal/ui/chakra";

import { FAN_CLUB_COMMON_DESCRIPTION, FAN_CLUB_TITLE } from "../constants/crew";
import { Header } from "../features/Header";
import { NotFound } from "../features/NotFound";

const Page: FC = () => {
  return (
    <>
      {/* NOTE: 最低限の設定だけするために直接next-head-seoを使用している */}
      <NextHeadSeo
        title={`404 Not Found | ${FAN_CLUB_TITLE}`}
        description={FAN_CLUB_COMMON_DESCRIPTION}
      />
      <Flex minHeight={"100vh"} direction={"column"}>
        <Header />

        <Container
          flex={"1 1 auto"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          maxWidth={`${980 + 32}px`}
        >
          <NotFound />
        </Container>
      </Flex>
    </>
  );
};
export default Page;
